
import BtnMain from '../parts/button/BtnMain.vue'
// import ArrowDownSVG from '@/components/svg/icon/ArrowDownSVG.vue'
import bodytext from '@/mixins/bodytext'
export default {
  components: {
    BtnMain,
    // ArrowDownSVG
  },
  mixins: [bodytext],
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      tl: null,
    }
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        toggleActions: 'play reset play reverse',
        start: 'top center',
      },
    })
    this.tl.scrollTrigger.disable()

    if (this.$refs.heading) {
      const heading = new this.$SplitText(this.$refs.heading, { type: 'words' })
      this.tl.from(heading.words, { opacity: 0, translateX: 64, duration: 0.1, stagger: 0.05 }, '<')
    }

    if (this.$refs.body) {
      const body = new this.$SplitText(this.$refs.body, { type: 'chars, words' })
      this.tl.from(body.chars, { delay: 0.3, opacity: 0.3, color: '#42795C', duration: 0.2, stagger: 0.005 }, '<')
    }
    this.$emit('sectionMounted')
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
  },
}
